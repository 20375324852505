.resaListElement {
    transition: .3s;
    border-bottom: 1px rgb(88, 88, 88) solid;
    padding: 20px 20px 20px 30px;
}

.resaListElement:hover {
    background-color: var(--planning-resa-highlight-background);
    
    color: black
}

.past {
    transition: .3s;
    color: rgb(255, 139, 139);
}

.resaListElement:hover .past {
    color: black
}

h2 {
    margin-top: 0;
    margin-bottom: 5px;
}

.resaListElement .date {
    margin-top: 0;
}