.App {
    padding: 0;
    background-color: var(--app-background-main);
    color: white;
}

.blue {
    color: var(--theme-main)
}

.noHover {
    pointer-events: none;
}

.interactable {
    cursor: pointer;
}

.textlink {
    font-weight: 500;
}

.textlink:hover {
    text-decoration: underline;
}

.FrontPage {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    transition: .5s;
}

.FrontPage[hide="true"] {
    opacity: 0;
}

.Front-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .Front-logo {
        animation: Front-logo-spin infinite 20s linear;
    }
}

@keyframes Front-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.Front-link {
    color: var(--theme-main);
}

.loginBoxInputZone {
    all: unset;
    background-color: #21242c;
    border: 1px solid;
    border-radius: 100vmin;
    vertical-align: middle;
    padding: 20px 20px 20px 20px;
    color: none;
    transition: .3s;
    margin-bottom: 20px;
}

.frontbutton {
    all: unset;
    background-color: #21242c;
    border: 1px solid;
    border-radius: 100vmin;
    vertical-align: middle;
    padding: 20px 50px 20px 50px;
    color: none;
    transition: .3s;
    cursor: pointer;
}

.frontbutton:hover {
    background-color: var(--theme-button-hover);
    color: black;
    border-color: var(--theme-button-hover);
}

.alertIcon {
    width: 1em;
    height: 1em;
    transition: 0.5s ease;
    margin-left: 5px;
    vertical-align: middle;
}

.iconButton {
    width: 50px;
    height: 50px;
    transition: 0.5s ease;
    border-radius: 100vmin;
}

.iconButton:hover {
    background-color: #484e58;
}

.padding-left {
    padding: 0px 0px 0px 30px;
}

.warning {
    color: lightcoral;
}

.confirm-popup {
    color: black;
    background-color: white;
    padding: 30px;
    font-weight: bold;
    border-radius: 20px;
}

.confirm-popup button {
    background-color: white;
    border: 1px solid black;
    padding: 20px;
    border-radius: 20px;
    transition: .3s;
}

.confirm-popup button:hover {
    background-color: red;
    border: 1px solid red;
    color: white;
    transition: .3s;
}

.confirm-popup button[action='logout']:hover {
    background-color: var(--theme-button-hover);
    border: 1px solid var(--theme-button-hover);
    color: black;
    transition: .3s;
}

.confirm-popup p {
    margin-top: 0px;
}

.conflict-popup {
    color: black;
    background-color: white;
    padding: 30px;
    font-weight: bold;
    border-radius: 20px;
}

.conflict-popup p {
    margin-top: 0px;
}

.conflictPopupTitle {
    font-size: large;
    font-weight: bolder;
}

.iconButton[fail="1"] {
    background-color: rgb(149, 0, 0);
}

.iconButton[fail="1"]:hover {
    background-color: rgb(212, 0, 0);
}

.no-margin {
    margin: 0;
}

.verticalalign-middle {
    vertical-align: middle;
}