.worknav {
    display: flex;
    flex-direction: row;
    bottom: 0;
}

.navButton {
    vertical-align: middle;
    width: 180px;
    height: 60px;
    color: none;
    transition: .3s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    background-color: var(--tab-off-background);
    border: 1px solid var(--tab-off-border);
    border-bottom: none;
    border-right: none;
    color: var(--tab-text);
    z-index: 2;
}

.navButton:hover, .navButton[loading="1"] {
    background-color: var(--tab-alt-background);
    color: var(--tab-alt-text);
    border-color: var(--tab-alt-border);
}

.navButton[current="1"] {
    background-color: var(--tab-on-background);
    color: var(--tab-on-text);
    border-color: var(--tab-on-border);
}

.pendingIcon {
    height: 40px;
}

@media (prefers-reduced-motion: no-preference) {
    .pendingIcon {
        animation: pending-icon-spin infinite 1s linear;
    }
}

@keyframes pending-icon-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}